/* Slider Settings */
.slider {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.slider--width {

  display: flex;
  
}

.slider--item {
  transition: all ease .5s;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Slider Controls */ 
.slider--controls button{
  position: absolute;
  top: 50%;
  
  background-color: transparent;
  border: transparent;
  
  font-size: 3rem;
  color: #fff;
  
  cursor: pointer;
  transition: all ease .3s;
}

.slider--controls button:hover {
  color: #2a2a2a;
}

.slider--controls button:nth-child(1) {
  left: 30%;
}

.slider--controls button:nth-child(2) {
  right: 30%;
}
/* Slider Content */

.card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 450px;
  height: 450px;

  margin-top: 80px;

  border-radius: 20px;
}

.title {
  color: #2a2a2a;
  
  font-weight: 800;
  font-size: 2rem;
  text-transform: uppercase;
  
  padding: 20px;
}

.image img{
  width: 350px;
  height: 230px;
  
  border-radius: 20px;
}

.description {
  padding: 20px;

  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  
  color: #2a2a2a;
}

button {
  background-color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 20px 50px;
  border: transparent;
  transition: all ease .3s;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button:hover {
  background-color: #2a2a2a;
  font-size: 1.3rem;
  color: #fff;
}

button span {
  margin-left: 20px;
}

@media (min-width: 900px) and (max-width: 1400px) {
  .slider--controls button:nth-child(1) {
    left: 25%;
  }
  
  .slider--controls button:nth-child(2) {
    right: 25%;
  }

  .card {
    margin-top: auto;
  }
}

@media (max-width: 899px) {

  .slider--item {
    justify-content: center;
    align-items: center;
  }

  .description {
    padding: 10px 20px;
  }

  .title {
    font-size: 1.7rem;
  }

  .image img{
    width: 300px;
    height: 150px;
  }

  .card {
    width: 100%;
    height: 420px;
    margin-top: auto;

  }

}