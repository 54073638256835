.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #2a2a2a;
  color: #fff;
  font-size: 1.2rem;
}

.footer a {
  color: #fff;
  text-decoration: none;
  margin-left: 8px;
}